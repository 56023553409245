import $ from 'jquery';
import 'slick-carousel/slick/slick';
import { getState } from '../../../store';

import './moveo_slick.css';
import './carousel.css';
import { isMobile } from '../../../utils';

const moveoCarouselMessage = ({ cards }, avatar) => {
  const renderButton = ({ label, type, url = '', value = '' }) => {
    const { background_color, accent_color } = getState();
    switch (type) {
      case 'url':
        return `<a  href='${url}' target='_blank' class='carousel-card__button '
                style="background:${accent_color};border:1px solid ${background_color};color:${background_color}">
                ${label}
                </a>`;
      case 'postback':
        return `<button data-text="${value}" class='carousel-card__button '
                style="background:${accent_color};border:1px solid ${background_color};color:${background_color}">
                ${label}
                </button>`;
      case 'phone':
        return `<a  href='tel:${value}' target='_blank' class='carousel-card__button '
                style="background:${accent_color};border:1px solid ${background_color};color:${background_color}">
                ${label}
                </a>`;
      default:
        return null;
    }
  };

  const addDefaultAction = (index, { type, url }) => {
    const { default_action } = cards[index];
    if (default_action) {
      if (default_action.type === 'url' && type === 'image') {
        return `<a href='${default_action.url}' target='_blank'><img class='carousel-card__image ' src='${url}' /></a>`;
      }
      if (default_action.type === 'phone' && type === 'image') {
        return `<a href='tel:${default_action.value}' target='_blank'><img class='carousel-card__image ' src='${url}' /></a>`;
      }
      if (default_action.type === 'postback' && type === 'image') {
        return `<img data-text="${default_action.value}" class='carousel-card__image carousel-card__button' src='${url}' />`;
      }
    }
    if (type === 'video') {
      return `<video controls>
        <source src="${url}#t=0.1" type="video/mp4" />
          Your browser does not support the video tag.
        </video>`;
    }
    return `<img class='carousel-card__image ' src='${url}' />`;
  };

  const fetchCards = () => {
    return cards.map(({ title, subtitle = '', media, buttons = [] }, index) => {
      return `<div class='carousel-card__wrapper'> <div class='carousel-card'>
              <div class='carousel-card__image-container'>
              ${addDefaultAction(index, media)}
              </div>
              <p class='carousel-card__title'>${title}</p>
              <p class='carousel-card__subtitle'>${subtitle}</p>
              <div class='carousel-card__buttons-container'>
              ${buttons.map((button) => renderButton(button)).join('')}
              </div>
              </div></div>`;
    });
  };

  const arrowIcon = `<svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 1L1 5L5 9" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>`;
  setTimeout(() => {
    const carousel = $('.carousel-container:not(.slick-initialized)');
    $('.carousel-container').removeClass('latest');
    carousel.addClass('latest');

    carousel.slick({
      infinite: false,
      slidesToShow: 1,
      prevArrow: !isMobile()
        ? `<div class="carousel__arrow arrow-left">
                  ${arrowIcon}
                  </div>`
        : false,
      nextArrow: !isMobile()
        ? `<div class="carousel__arrow arrow-right">
                  ${arrowIcon}
                  </div>`
        : false,
      swipeToSlide: true,
      draggable: isMobile(),
      centerMode: isMobile(),
      centerPadding: isMobile() ? '15%' : '0',
    });

    $('.carousel-message').css('display', 'block');
    $('.carousel-message').animate({ opacity: '1' }, 100);

    $('.carousel-container').on(
      'beforeChange',
      (event, _, currentSlide, nextSlide) => {
        $(event.target)
          .siblings('.card-indicator')
          .children('.current-card')
          .text(nextSlide + 1);
        if (currentSlide === 0) {
          $(event.target).children('.arrow-left').css('visibility', 'visible');
        }
        if (nextSlide === 0) {
          $(event.target).children('.arrow-left').css('visibility', 'hidden');
        }
        if (currentSlide === cards.length - 1) {
          $(event.target).children('.arrow-right').css('visibility', 'visible');
        }
        if (nextSlide === cards.length - 1) {
          $(event.target).children('.arrow-right').css('visibility', 'hidden');
        }
      },
    );
  }, 100);

  return `<li class="moveo__other carousel-message">
    <img class="bot__image" src="${avatar}" />
    <div class='carousel-container' style=''>
      ${fetchCards()}
      </div>
      <div class='card-indicator'>
      <span class='current-card'>1</span>/<span>${cards.length}</span>
      </div>
    </li>`;
};
export default moveoCarouselMessage;
